import { useEffect } from "react";
import ContactDetailsSection from "../../components/ContactDetailsSection/ContactDetailsSection";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./ContactUsPage.css";

export default function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  return (
    <div className="ContactUsPage--MainContainer">
      <Header />
      <div style={{ display: "flex", flexDirection: "column",alignItems:"center" }}>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: "bold",
            marginTop: "20px",
          }}
        >
          CONTÁCTANOS
        </text>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          CUÉNTANOS MÁS SOBRE TU PROYECTO
        </text>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "10px",
            width:"90%"
          }}
        >
          ¡Nos encantaría escuchar tus ideas y trabajar juntos en tu próximo
          proyecto de construcción! Ya sea que tengas preguntas, comentarios o
          estés listo para comenzar, no dudes en ponerte en contacto con nuestro
          equipo. Estamos aquí para ayudarte en cada paso del camino y hacer
          realidad tus sueños de construcción.
        </text>
      </div>
      <ContactForm />
      <ContactDetailsSection/>
      <Footer />
    </div>
  );
}
