import { useNavigate } from "react-router-dom";
import RoundedButton from "../RoundedButton/RoundedButton";
import "./GallerySection.css"

export default function GallerySection() {
    const navigate = useNavigate();
    return(
        <div className="GallerySection--MainContainer">
            <div style={{display:"inline"}}>
                <text style={{fontFamily:"Montserrat",fontSize:"24px",fontWeight:"bold"}}>GALERÍA</text>
            </div>
            <text style={{fontFamily:"Montserrat",fontSize:"16px",fontWeight:"bold"}}>BUSQUE EL TRABAJO DE SU INTERÉS</text>
            <text style={{fontFamily:"Montserrat",fontSize:"14px",fontWeight:"bold",marginTop:"10px",marginBottom:"10px"}}>Contamos con una galería organizada para que nuestros clientes puedan concentrarse en el tipo de trabajo que quieren realizar.</text>
            <RoundedButton onClick={()=>{navigate("/galeria")}} text={"Visitar Galería"}/>
        </div>
    );
}