import React, { useState } from "react";
import "./MobileMenu.css"; // Import your CSS file for styling
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import handleScroll from "../../utils/handleScroll";
//TODO Programar el outside click para que se cierre el menu.

type mobileMenuProps =  {
  servicesSectionRef?:any;
}
const MobileMenu = ({servicesSectionRef}:mobileMenuProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // State to track if the menu is open or not
  

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu state
  };

  /**-------------------------------------------------------------------------------*/
  /** HANDLERS HERE-----------------------------------------------------------------*/
  /**-------------------------------------------------------------------------------*/
  const handleClickOutsideMenu = ()=>{
    setIsOpen(false)
  };
  const handleHomeClick = () => {
    navigate("/home");
    setIsOpen(false);
  };
  const handleContactUsClick = () => {
    navigate("/contactanos");
    setIsOpen(false);
  };
  const handleServicesClick = () => {
    navigate("/servicios/lista"); 
    if(servicesSectionRef){

      handleScroll(servicesSectionRef.current);
    }   
    setIsOpen(false);
  };
  const handleProductsClick = () => {
    //navigate("/contactanos");
    setIsOpen(false);
  };
  const handleGalleryClick = () => {
    navigate("/galeria");
    setIsOpen(false);
  };
  const handleAboutUsClick = () => {
    navigate("/quienessomos");
    setIsOpen(false);
  };

  const ref:any = useOutsideClick(handleClickOutsideMenu);

  return (
    <div ref={ref} className="mobile-menu">
      {/* Render menu options only if the menu is open */}
      {isOpen && (
        <ul className="menu-options">
          <li onClick={handleHomeClick}>Inicio</li>
          <li onClick={handleServicesClick}>Servicios</li>
          {/* <li onClick={handleProductsClick}>Productos</li> */}
          <li onClick={handleGalleryClick}>Galería</li>
          <li onClick={handleContactUsClick} style={{color:"#ffb800"}}>Contáctanos</li>
          <li onClick={handleAboutUsClick}>¿Quiénes somos?</li>
          {/* Add more menu options as needed */}
        </ul>
      )}
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? (
          <span
            style={{ fontSize: "30px" }}
            className="material-symbols-outlined"
          >
            close
          </span>
        ) : (
          <span
            style={{ fontSize: "30px" }}
            className="material-symbols-outlined"
          >
            menu
          </span>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
