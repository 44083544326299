import Carousel from "../Carousel/Carousel";
import CheckBoxFeature from "../CheckBoxFeature/CheckBoxFeature";
import LeftHexagonImage from "../../assets/Hexagon 1 Chamfer Left.png";
import RightHexagonImage from "../../assets/Hexagon 2 Chamfer Right.png";
import "./GalleryDisplaySection.css";
import Button from "@mui/material/Button";
import RoundedButton from "../RoundedButton/RoundedButton";
import { useNavigate } from "react-router-dom";
import CategoriesCarousel from "../CategoriesCarousel/CategoriesCarousel";
import { useEffect, useState } from "react";
import { imageListBathroom } from "./imageListBathroom";
import { imageListBedroom } from "./imageListBedroom";
import { imageListKitchen } from "./imageListKitchen";
import { imageListPatio } from "./imageListPatio";
import { imageListHouse } from "./imageListHouse";

export default function GalleryDisplaySection() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const imageListAll = imageListHouse.concat(imageListPatio,imageListKitchen,imageListBedroom,imageListBathroom)
  const [activeCategoryImageList,setActiveCategoryImageList] = useState(imageListAll);

  
  useEffect(() => {
    console.log(activeCategoryImageList)
    switch (selectedCategory) {
      case "all":

        setActiveCategoryImageList(imageListAll)
        break;
      case "bedroom":
        setActiveCategoryImageList(imageListBedroom)
        break;
      case "bathroom":
        setActiveCategoryImageList(imageListBathroom)
        break;
        case "kitchen":
          setActiveCategoryImageList(imageListKitchen)
          break;
          case "patio":
          setActiveCategoryImageList(imageListPatio)
          break;
          case "house":
          setActiveCategoryImageList(imageListHouse)
          break;

      default:
        break;
    }
  }, [selectedCategory]);
  const navigate = useNavigate();

  return (
    <div className="GalleryDisplaySection--MainContainer">
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "20px",
          fontWeight: "bold",
          color: "#FFB800",
        }}
      >
        GALERÍA
      </text>
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "20px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        SELECCIONE UNA CATEGORÍA
      </text>
      <div className="GalleryDisplay-CategoriesContainer">
      <img
          onClick={() => {
            setSelectedCategory("all");
          }}
          src="https://alfasip.automatic-men.com/public/category-icons/all-icon.png"
          alt=""
          {...(selectedCategory === "all"
            ? { className: "category-icon" }
            : { className: "category-icon-inactive" })}
        />
      <img
          onClick={() => {
            setSelectedCategory("house");
          }}
          src="https://alfasip.automatic-men.com/public/category-icons/house-icon.png"
          alt=""
          {...(selectedCategory === "house"
            ? { className: "category-icon" }
            : { className: "category-icon-inactive" })}
        />
        <img
          onClick={() => {
            setSelectedCategory("bathroom");
          }}
          src="https://alfasip.automatic-men.com/public/category-icons/bath-icon.png"
          alt=""
          {...(selectedCategory === "bathroom"
            ? { className: "category-icon" }
            : { className: "category-icon-inactive" })}
        />
        <img
          onClick={() => {
            setSelectedCategory("patio");
          }}
          src="https://alfasip.automatic-men.com/public/category-icons/patio-icon.png"
          alt=""
          {...(selectedCategory === "patio"
            ? { className: "category-icon" }
            : { className: "category-icon-inactive" })}
        />
        <img
          onClick={() => {
            setSelectedCategory("kitchen");
          }}
          src="https://alfasip.automatic-men.com/public/category-icons/kitchen-icon.png"
          alt=""
          {...(selectedCategory === "kitchen"
            ? { className: "category-icon" }
            : { className: "category-icon-inactive" })}
        />
        <img
          onClick={() => {
            setSelectedCategory("bedroom");
          }}
          src="https://alfasip.automatic-men.com/public/category-icons/bed-icon.png"
          alt=""
          {...(selectedCategory === "bedroom"
            ? { className: "category-icon" }
            : { className: "category-icon-inactive" })}
        />
      </div>
      <Carousel children={activeCategoryImageList} />
      <div className="GalleryDisplaySection--ACtionButtonContainer">
        <RoundedButton
          icon={<span className="material-symbols-outlined">send</span>}
          onClick={() => {
            navigate("/contactanos");
          }}
          text={"Contáctanos"}
        />
      </div>
    </div>
  );
}
