export const imageListKitchen = [
    <div
      style={{
        transition: "ease-in-out 2s",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <img
        src="https://alfasip.automatic-men.com/public/gallery/kitchen/kitchen_1.jpg"
        alt=""
        style={{ position: "absolute", height: "100%", objectFit: "cover" }}
      />
    </div>,
    <div
      style={{
        transition: "ease-in-out 2s",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <img
        src="https://alfasip.automatic-men.com/public/gallery/kitchen/kitchen_2.jpg"
        alt=""
        style={{ position: "absolute", height: "100%", objectFit: "cover" }}
      />
    </div>,
  ];