import "./TestimonialChip.css";

type TestimonialChipProps = {
  avatar?: any;
  name: any;
  testimonial: any;
};
export default function TestimonialChip({
  avatar,
  name,
  testimonial,
}: TestimonialChipProps) {
  const defaultAvatar = (
    <span className="material-symbols-outlined" style={{ color: "#FFB800" }}>
      account_circle
    </span>
  );
  return (
    <div className="TestimonialChip--MainContainer">
      <div className="TestimonialChip--AvatarContainer">
        {avatar ? avatar : defaultAvatar}
      </div>
      <text
        style={{ fontFamily: "Montserrat", fontSize: "20px", color: "#FFB800" }}
      >
        {name}
      </text>
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          color: "white",
          marginBottom: "20px",
        }}
      >
        {testimonial}
      </text>
    </div>
  );
}
