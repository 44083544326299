import { useNavigate } from "react-router-dom";
import CheckBoxFeature from "../CheckBoxFeature/CheckBoxFeature";
import RoundedButton from "../RoundedButton/RoundedButton";
import "./ServicesSection.css";

export default function ServicesSection() {
  const navigate = useNavigate();
  return (
    <div className="ServicesSection--MainContainer">
      <text
        style={{ fontFamily: "Montserrat", fontSize: "24px", color: "#FFB800" }}
      >
        SERVICIOS
      </text>
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          color: "white",
          marginBottom: "20px",
        }}
      >
        ¿QUÉ PODEMOS HACER POR TÍ?
      </text>
      <CheckBoxFeature feature="Fabricación e instalación de paneles SIP" />
      <CheckBoxFeature feature="Construcción de casas" />
      <CheckBoxFeature feature="Hormigones estampados" />
      <CheckBoxFeature feature="Urbanización" />
      <CheckBoxFeature feature="Gasfitería" />
      <CheckBoxFeature feature="Carpintería" />
      <CheckBoxFeature feature="Albañilería" />
      <CheckBoxFeature feature="Terrazas" />
      <CheckBoxFeature feature="Quinchos" />
      <CheckBoxFeature feature="Paisajismos" />
      <CheckBoxFeature feature="Electricidad" />
      <div style={{marginTop:"20px"}}>
        <RoundedButton
          icon={<span className="material-symbols-outlined">send</span>}
          onClick={() => {
            navigate("/contactanos");
          }}
          text={"Contáctanos"}
        />
      </div>
    </div>
  );
}
