import React, { useState } from "react";
import "./RoundedButton.css"; // Import your CSS file

type RoundedButtonProps = {
  icon?: any;
  text: any;
  onClick: any;
};
const RoundedButton = ({ icon, text, onClick }: RoundedButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="RoundedButton--MainContainer"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {icon}
      <text
        style={{
          fontFamily: "Montserrat",
          fontWeight: "bold",
          fontSize: "17px",
        }}
      >
        {text}
      </text>
    </div>
  );
};

export default RoundedButton;
