import { useEffect, useState } from "react";
import "./ContactForm.css"
import TextField from '@mui/material/TextField'
import RoundedButton from "../RoundedButton/RoundedButton";
import emailjs from "@emailjs/browser";
import Toast from "../Toast/Toast";

export default function ContactForm() {

    /**-------------------------------------------------------------------------------*/
    /** STATE VARIABLES HERE                                                          */
    /**-------------------------------------------------------------------------------*/
    const [name,setName] = useState("");
    const [nameValid,setNameValid] = useState(false);
    const [email,setEmail] = useState("");
    const [emailValid,setEmailValid] = useState(false)
    const [phone,setPhone] = useState("");
    const [phoneValid,setPhoneValid] = useState(true);
    const [message,setMessage] = useState("");
    const [messageValid,setMessageValid] = useState(false);
    const [isToastVisisble,setIsToastVisible] = useState(true);
    const [messageSent,setMessageSent] = useState(false);
    
    /**-------------------------------------------------------------------------------*/
    /** HANDLERS AND FUNCTIONS HERE                                                   */
    /**-------------------------------------------------------------------------------*/
    const sendMessage = () => {
      let templateParams = {
        fromName:name,
        email: email,
        message: message,
        phoneNumber:phone
      };
      if (emailValid === true && messageValid === true && nameValid) {
        emailjs
          .send(
            "service_contacto_alfasip",
            "alfasip_contact_form",
            templateParams,
            "nKyMXfRB34XsRJV-H"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
              setIsToastVisible(true)
              setMessageSent(true)
              //handleClose();
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
      } else {
        alert("No se ha podido enviar el mensaje. Revise los datos del formulario.");
      }
    };
    const handleEmailChange = (event:any)=>{
      setEmail(event.target.value)
      if (emailValidation(event.target.value)===true) {
        setEmailValid(true);
    } else{
      setEmailValid(false);
    }
    };

    const handlePhoneChange = (event:any)=>{
      setPhone(event.target.value)
      if (phoneValidation(event.target.value)===true) {
        setPhoneValid(true);
    } else{
      setPhoneValid(false);
    }
    if (event.target.value.length === 0){
      setPhoneValid(true);
    }
    }
    const handleMessageChange = (event: any) => {
      setMessage(event.target.value)
      if (event.target.value.length !== 0) {
        setMessageValid(true);
      } else {
        setMessageValid(false);
      }
    };
    const handleNameChange = (event: any) => {
      setName(event.target.value)
      if (nameValidation(event.target.value)===true) {
        setNameValid(true);
      } else {
        setNameValid(false);
      }
    };
    //-----------------------------------------------------------
    // VALIDATIONS HERE
    //-----------------------------------------------------------
    const nameValidation = (name:string)=>{
      return name.length!==0;
    }
    const emailValidation = (email:string) => {
      const regex = new RegExp(
        "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
      );
      return regex.test(email);
    };
    const phoneValidation = (phone:string) => {
      const regex = new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$");
      return regex.test(phone);
    };
    /**-------------------------------------------------------------------------------*/
    /** USE EFFECTS HERE                                                              */
    /**-------------------------------------------------------------------------------*/
    useEffect(()=>{
      if(isToastVisisble===true){
        setMessageSent(false);
      }
    },[isToastVisisble]);

    return(
        <div className="ContactForm--MainContainer">

                
            <TextField
              id="name"
              label="Nombre"
              value={name}
              onChange={handleNameChange}  
              margin="normal"  
              {...(nameValid)?{helperText:""}:{helperText:"El nombre no debe estar vacío."}} 
              {...(nameValid)?{}:{error:true}}  
              style={{width:"90%"}}  
              size="small"     
            />

            <TextField
              id="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              margin="normal"
              {...(emailValid)?{helperText:""}:{helperText:"El email no es correcto."}} 
              {...(emailValid)?{}:{error:true}} 
              style={{width:"90%"}} 
              size="small"  
            />
            <TextField
              id="phone"
              label="Teléfono"
              value={phone}
              onChange={handlePhoneChange}  
              type="tel"   
              margin="normal"  
              {...(phoneValid)?{helperText:""}:{helperText:"El teléfono no es correcto."}} 
              {...(phoneValid)?{}:{error:true}} 
              style={{width:"90%"}}  
              size="small"         
            />
            <TextField
              id="message"
              label="Mensaje"
              value={message}
              onChange={handleMessageChange} 
              multiline  
              rows={10}  
              margin="normal" 
              type="text"  
              {...(messageValid)?{helperText:""}:{helperText:"El mensaje no puede estar vacío."}} 
              {...(messageValid)?{}:{error:true}}  
              style={{width:"90%"}}  
              size="small"  
            />
            <RoundedButton text={"Enviar mensaje"} onClick={sendMessage}/>
            {messageSent===true?(<Toast
          message="Mensaje enviado correctamente"
          duration={3000}
          onClose={() => setIsToastVisible(false)}
        />):(undefined)}
        </div>
    );
}