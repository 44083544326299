import "./Header.css"
import MobileLogo from "../../assets/MOBILE LOGO 5.png"
import MobileMenu from "../MobileMenu/MobileMenu"
import { useNavigate } from "react-router-dom";
type headerProps = {
    servicesSectionRef?:any;
}
export default function Header({servicesSectionRef}:headerProps) {
    const navigate = useNavigate();
    return(
        <div className="Header--MainContainer">
            <img src={MobileLogo} alt="AlfaSip Logo" height={"80px"} onClick={()=>{navigate("/")}}/>
            <MobileMenu servicesSectionRef={servicesSectionRef}/>
        </div>
    )
}