import React, { useState, useEffect } from 'react';
import './Toast.css'; // Import your CSS file for styling

type ToastProps = {
    message:any;
    duration:any;
    onClose:any
}
const Toast = ({ message,duration, onClose }:ToastProps) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className={`toast ${isVisible ? 'show' : 'hide'}`}>
      <p>{message}</p>
    </div>
  );
};

export default Toast;