import { useEffect } from "react";
import ContactDetailsSection from "../../components/ContactDetailsSection/ContactDetailsSection";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./AboutUsPage.css";
import RoundedButton from "../../components/RoundedButton/RoundedButton";
import { useNavigate } from "react-router-dom";

export default function AboutUsPage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  return (
    <div className="AboutUsPage--MainContainer">
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: "normal",
            marginTop: "20px",
          }}
        >
          <b>ALFA</b> SIP
        </text>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          CONSTRUCCIONES EFICIENTES
        </text>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "normal",
            marginTop: "10px",
            marginBottom: "10px",
            width: "90%",
          }}
        >
          En <b>ALFA</b> SIP, nos enorgullecemos de ser una firma dedicada a la
          excelencia en la construcción y al servicio al cliente. Fundada por
          <b> Miguel Donoso Ortiz</b> y <b>Boris Sánchez Osorio</b>, nuestra empresa ha
          establecido una reputación sólida y confiable en la industria de la
          construcción.
        </text>

        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "normal",
            marginTop: "10px",
            marginBottom: "10px",
            width: "90%",
          }}
        >
          Con una combinación única de experiencia, visión y compromiso,
          nuestros socios principales lideran un equipo talentoso de
          constructores y profesionales en el sector. Nuestra empresa se
          distingue por la calidad de nuestras obras, la integridad en nuestros
          procesos y la atención meticulosa a cada detalle.
        </text>

        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "10px",
            width: "90%",
          }}
        >
          Nuestra Misión
        </text>

        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "normal",
            marginTop: "10px",
            marginBottom: "10px",
            width: "90%",
          }}
        >
          En <b>ALFA</b> SIP, nuestra misión es superar las expectativas de nuestros
          clientes en cada proyecto que emprendemos. Nos esforzamos por ofrecer
          soluciones innovadoras, seguras y sostenibles que mejoren la calidad
          de vida de las comunidades que servimos.
        </text>
        <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        Nuestro Equipo
      </text>

      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "normal",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        Nuestro equipo está formado por un grupo diverso de constructores
        altamente capacitados y dedicados. Desde ingenieros y arquitectos hasta
        técnicos especializados, cada miembro de nuestro equipo aporta
        habilidades únicas y un compromiso inquebrantable con la excelencia.
      </text>

      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        Nuestros Valores
      </text>

      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "normal",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        En <b>ALFA</b> SIP, nos regimos por los más altos estándares éticos y
        profesionales. La honestidad, la transparencia y el respeto son los
        pilares fundamentales de nuestra cultura empresarial. Nos esforzamos por
        establecer relaciones sólidas y duraderas con nuestros clientes, basadas
        en la confianza mutua y el cumplimiento de nuestras promesas.
      </text>

      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        Nuestra Visión
      </text>

      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "normal",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        Nuestra visión es convertirnos en líderes reconocidos en la industria de
        la construcción, destacando por nuestra innovación, calidad y compromiso
        con la sostenibilidad. Aspiramos a ser un referente en la creación de
        espacios que inspiren, enriquezcan y perduren en el tiempo.
      </text>

      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "normal",
          marginTop: "10px",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        En <b>ALFA</b> SIP, cada proyecto es una oportunidad para demostrar nuestro
        compromiso con la excelencia y la satisfacción del cliente. Nos
        enorgullece el trabajo que hacemos y estamos comprometidos a superar las
        expectativas en cada paso del camino.
      </text>
      </div>

      <div className="AboutUsPage-ActionButtonsContainer">

      <RoundedButton
          icon={<span className="material-symbols-outlined">send</span>}
          onClick={() => {navigate("/contactanos")}}
          text={" Contáctanos"}
        />
        <RoundedButton icon={<span className="material-symbols-outlined">
gallery_thumbnail
</span>} onClick={()=>{navigate("/galeria")}} text={" Visitar Galería"}/>
      </div>
      

      <Footer />
    </div>
  );
}
