import React, { useEffect, useState } from 'react';
import './Carousel.css';

type CarouselProps = {
    children:any;
};
const Carousel = ({ children }:CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e:any) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e:any) => {
    if (!startX) return;
    const currentX = e.touches[0].clientX;
    const difference = startX - currentX;
    if (difference > 0) {
      nextSlide();
    } else {
      prevSlide();
    }
    setStartX(null);
  };

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % children.length);
  };

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + children.length) % children.length);
  };

  useEffect(() => {
   const interval = setInterval(nextSlide, 8000); // Change slide every 1 second
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    setCurrentIndex(0);
   }, [children]);

  return (
    <div className="carousel" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <button className="prev" onClick={prevSlide}>&#10094;</button>
      <div className="slides-container">
        {children.map((child:any, index:any) => (
           <div key={index} className={index === currentIndex ? 'slide--active' : 'slide'}>
            {child}
          </div>
        ))}
      </div>
      <button className="next" onClick={nextSlide}>&#10095;</button>
    </div>
  );
};

export default Carousel;