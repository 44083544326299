import { useEffect, useRef } from "react";
import AboutSection from "../../components/AboutSection/AboutSection";
import CTASection from "../../components/CTASection/CTASection";
import Carousel from "../../components/Carousel/Carousel";
import CheckBoxFeature from "../../components/CheckBoxFeature/CheckBoxFeature";
import Footer from "../../components/Footer/Footer";
import GallerySection from "../../components/GallerySection/GallerySection";
import Header from "../../components/Header/Header";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import TestimonialsSection from "../../components/TestimonialsSection/TestimonialsSection";
import "./LandingPage.css";
import { useParams } from "react-router-dom";
import handleScroll from "../../utils/handleScroll";

export default function LandingPage() {
  let { section } = useParams();
  const servicesListRef: any = useRef();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    if (section === "lista") {
      handleScroll(servicesListRef.current);
    }
  }, [section]);
  return (
    <div className="LandingPage--MainContainer">
      <Header servicesSectionRef={servicesListRef}/>
      <CTASection />
      <AboutSection />
      <div ref={servicesListRef}>
        <ServicesSection />
      </div>
      <GallerySection />
      <TestimonialsSection />
      <Footer />
    </div>
  );
}
