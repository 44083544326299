import { useNavigate } from "react-router-dom";
import RoundedButton from "../RoundedButton/RoundedButton";
import "./AboutSection.css"

export default function AboutSection() {
    const navigate = useNavigate();
    return(
        <div className="AboutSection--MainContainer">
            <div style={{display:"inline"}}>
                <text style={{fontFamily:"Montserrat",fontSize:"24px",fontWeight:"bold"}}>ALFA</text>
                <text style={{fontFamily:"Montserrat",fontSize:"24px"}}>SIP</text>
            </div>
            <text style={{fontFamily:"Montserrat",fontSize:"16px",fontWeight:"bold"}}>CONSTRUCCIONES EFICIENTES</text>
            <text style={{fontFamily:"Montserrat",fontSize:"14px",fontWeight:"bold",marginTop:"10px",marginBottom:"10px"}}>Nos dedicamos a crear hogares de calidad que superen las expectativas, enfocados en la excelencia, la innovación y la sostenibilidad. Nuestro objetivo es construir legados duraderos y generar un impacto positivo en las comunidades que servimos.</text>
            <RoundedButton onClick={()=>{navigate("/quienessomos")}} text={"¿Quiénes somos?"}/>
        </div>
    );
}