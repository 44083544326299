import Carousel from "../Carousel/Carousel";
import CheckBoxFeature from "../CheckBoxFeature/CheckBoxFeature";
import LeftHexagonImage from "../../assets/Hexagon 1 Chamfer Left.png";
import RightHexagonImage from "../../assets/Hexagon 2 Chamfer Right.png";
import "./CTASection.css";
import Button from "@mui/material/Button";
import RoundedButton from "../RoundedButton/RoundedButton";
import { useNavigate } from "react-router-dom";

export default function CTASection() {
  const navigate = useNavigate();
  const carouselItems = [
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width:"100%",
        justifyContent: "space-evenly",
      }}
    >
      <CheckBoxFeature feature="Fabricación e instalación de paneles SIP" />
      <CheckBoxFeature feature="Construcción de casas" />
      <CheckBoxFeature feature="Hormigones estampados" />
      <CheckBoxFeature feature="Urbanización" />
    </div>,
    <div
      style={{
        transition: "ease-in-out 2s",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width:"100%",
        justifyContent: "space-evenly",
      }}
    >
      <CheckBoxFeature feature="Gasfitería" />
      <CheckBoxFeature feature="Carpintería" />
      <CheckBoxFeature feature="Albañilería" />
      <CheckBoxFeature feature="Terrazas" />
    </div>,
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width:"100%",
        justifyContent: "space-evenly",
      }}
    >
      <CheckBoxFeature feature="Quinchos" />
      <CheckBoxFeature feature="Paisajismos" />
      <CheckBoxFeature feature="Electricidad" />
    </div>,
  ];
  return (
    <div className="CTASection--MainContainer">
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "20px",
          fontWeight: "bold",
          color: "#FFB800",
        }}
      >
        CONSTRUCTORA
      </text>
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "20px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        E INMOBILIARIA
      </text>
      <Carousel children={carouselItems} />
      <div className="CTASection--ACtionButtonContainer">
        <img
          src={LeftHexagonImage}
          alt="Ejemplo Panel SIP"
          style={{ display: "flex", height: "100px" }}
        />
        <RoundedButton
          icon={<span className="material-symbols-outlined">send</span>}
          onClick={() => {navigate("/contactanos")}}
          text={"Contáctanos"}
        />
        <img
          src={RightHexagonImage}
          alt="Ejemplo Panel SIP"
          style={{
            display: "flex",
            height: "110px",
            fontFamily: "Montserrat",
            fontWeight: "bold",
          }}
        />
      </div>
    </div>
  );
}
