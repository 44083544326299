import { useEffect } from "react";
import AboutSection from "../../components/AboutSection/AboutSection";
import CTASection from "../../components/CTASection/CTASection";
import Carousel from "../../components/Carousel/Carousel";
import CheckBoxFeature from "../../components/CheckBoxFeature/CheckBoxFeature";
import Footer from "../../components/Footer/Footer";
import GallerySection from "../../components/GallerySection/GallerySection";
import Header from "../../components/Header/Header";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import TestimonialsSection from "../../components/TestimonialsSection/TestimonialsSection";
import "./GalleryPage.css";
import GalleryDisplaySection from "../../components/GalleryDisplaySection/GalleryDisplaySection";

export default function GalleryPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  return (
    <div className="GalleryPage--MainContainer">
      <Header />
      <GalleryDisplaySection/>
      <Footer />
    </div>
  );
}
