import "./Footer.css"
import AlfasipLogo from "../../assets/DESKTOP LOGO.png"
import InstagramLogo from "../../assets/instagram-logo.png"
import FacebookLogo from "../../assets/facebook-logo.png"
import PhoneLogo from "../../assets/phone-logo.png"
import WhatsAppLogo from "../../assets/whatsapp-logo.png"
import RedCompraBanner from "../../assets/redcompra.png"
import { useNavigate } from "react-router-dom"
export default function Footer() {
    const navigate = useNavigate();
    const handleLogoClick = ()=>{
        navigate("/")
    }
    return(
        <div className="Footer--MainContainer">
            <div className="Footer--LogContainer" onClick={handleLogoClick}>
                <img src={AlfasipLogo} alt="Alfasip Logo" />
            </div>
            <div className="Footer--ContactAndDirectLinsContainer">
                <div className="Footer-LeftContainer">
                    <text style={{fontFamily:"Montserrat",color:"#B3B3B3"}}>IR A</text>
                    <text style={{fontFamily:"Montserrat",color:"#FFFFFF"}} onClick={()=>{navigate("/")}}>Inicio</text>
                    <text style={{fontFamily:"Montserrat",color:"#FFFFFF"}} onClick={()=>navigate("/servicios/lista")}>Servicios</text>
                    {/* <text style={{fontFamily:"Montserrat",color:"#FFFFFF"}}>Productos</text> */}
                    <text style={{fontFamily:"Montserrat",color:"#FFFFFF"}} onClick={()=>{navigate("/galeria")}}>Galería</text>
                    <text style={{fontFamily:"Montserrat",color:"#ffb800"}} onClick={()=>{navigate("/contactanos")}}>Contáctanos</text>
                    <text style={{fontFamily:"Montserrat",color:"#FFFFFF"}} onClick={()=>{navigate("/quienessomos")}}>¿Quiénes somos?</text>
                </div>
                <div className="Footer-RightContainer">
                    <text style={{fontFamily:"Montserrat",color:"#B3B3B3"}}>CONTÁCTANOS</text>
                    <div className="Footer-SocialMediaContainer">
                        <a href="https://www.instagram.com/alfasip_paine"><img src={InstagramLogo} alt="Instagram Logo" /></a>
                        <a href="#"><img src={FacebookLogo} alt="Facebook Logo" /></a> 
                        <a href="https://api.whatsapp.com/send?phone=+56922077302"><img src={WhatsAppLogo} alt="Phone Logo" /></a>                       
                        <a href="tel:+56922077302"><img src={PhoneLogo} alt="Phone Logo" /></a>
                    </div>
                    <text style={{fontFamily:"Montserrat",color:"#B3B3B3"}}>ENCUÉNTRANOS EN</text>
                    <text style={{fontFamily:"Montserrat",color:"#FFFFFF",textAlign:"right"}}>Avenida Miguel Letelier, sitio 31 Lote C</text>
                    <img src={RedCompraBanner} alt="redcompra" width={"100%"}/>
                </div>
            </div>
        </div>
    )
}