import "./CheckBoxFeature.css";
import CheckBoxFilled from "../../assets/checkbox-filled.png"
type CheckBoxFeatureProps = {
  feature: string;
};
export default function CheckBoxFeature({ feature }: CheckBoxFeatureProps) {
  return (
    <div className="CheckBoxFeature--MainContainer">
      <img src={CheckBoxFilled} alt="Check Box Filled" />
      <text
        style={{
          fontFamily: "Montserrat",
          color: "white",
          fontSize: "16px",
          textAlign: "left",
          fontWeight:"bold"
        }}
      >
        {feature}
      </text>
    </div>
  );
}
