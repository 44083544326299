import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "../views/LandingPage/LandingPage";
import ContactUsPage from "../views/ContactUsPage/ContactUsPage";
import { useEffect } from "react";
import GalleryPage from "../views/GalleryPage/GalleryPage";
import AboutUsPage from "../views/AboutUsPage/AboutUsPage";

const RoutesComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}>
          <Route path=":blaka" element={<LandingPage />} />
        </Route>
        <Route path="/servicios" element={<LandingPage />}>
          <Route path=":section" element={<LandingPage />} />
        </Route>
        <Route path="/contactanos" element={<ContactUsPage />} />
        <Route path="/galeria" element={<GalleryPage />} />
        <Route path="/quienessomos" element={<AboutUsPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
