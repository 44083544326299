import CheckBoxFeature from "../CheckBoxFeature/CheckBoxFeature";
import "./ContactDetailsSection.css";
import InstagramLogo from "../../assets/instagram-logo.png";
import FacebookLogo from "../../assets/facebook-logo.png";
import PhoneLogo from "../../assets/phone-logo.png";
import WhatsAppLogo from "../../assets/whatsapp-logo.png";
import HomePinLogo from "../../assets/home-pin-logo.png";

export default function ContactDetailsSection() {
  return (
    <div className="ContactDetailsSection--MainContainer">
      <text
        style={{ fontFamily: "Montserrat", fontSize: "24px", color: "#FFB800" }}
      >
        MÁS SOBRE NOSOTROS
      </text>
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          color: "white",
          marginBottom: "20px",
        }}
      >
        NO DUDES EN CONTACTARNOS. ESTÁMOS A TU SERVICIO
      </text>
      <div className="ContactUsPage--ContactMethodContainer">
        <a
          style={{ display: "flex", textDecoration: "none" }}
          href="https://maps.app.goo.gl/ceGWgXVwrPjByfR99"
        >
          <span
            style={{ color: "#FFB800" }}
            className="material-symbols-outlined"
          >
            home_pin
          </span>

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            Avenida Miguel Letelier, sitio 31, Lote C
          </text>
        </a>
      </div>
      <div className="ContactUsPage--ContactMethodContainer">
        <a
          href="https://api.whatsapp.com/send?phone=+56922077302"
          style={{ display: "flex", textDecoration: "none" }}
        >
          <img src={WhatsAppLogo} alt="Phone Logo" />

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            +56 9 2207 7302
          </text>
        </a>
      </div>
      <div className="ContactUsPage--ContactMethodContainer">
        <a
          href="tel:+56922077302"
          style={{ display: "flex", textDecoration: "none" }}
        >
          <img src={PhoneLogo} alt="Phone Logo" />

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            +56 9 2207 7302
          </text>
        </a>
      </div>
      <div className="ContactUsPage--ContactMethodContainer">
        <a
          href="https://api.whatsapp.com/send?phone=+56974381907"
          style={{ display: "flex", textDecoration: "none" }}
        >
          <img src={WhatsAppLogo} alt="Phone Logo" />

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            +56 9 743801907
          </text>
        </a>
      </div>
      <div className="ContactUsPage--ContactMethodContainer">
        <a
          href="tel:+56974381907"
          style={{ display: "flex", textDecoration: "none" }}
        >
          <img src={PhoneLogo} alt="Phone Logo" />

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            +56 9 7438 1907
          </text>
        </a>
      </div>

      <div className="ContactUsPage--ContactMethodContainer">
        <a
          href="https://www.instagram.com/alfasip_paine"
          style={{ display: "flex", textDecoration: "none" }}
        >
          <img src={InstagramLogo} alt="Instagram Logo" />

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            @alfasip_paine
          </text>
        </a>
      </div>
      {/* <div className="ContactUsPage--ContactMethodContainer">
        <a href="#" style={{ display: "flex", textDecoration: "none" }}>
          <img src={FacebookLogo} alt="Facebook Logo" />

          <text
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
            }}
          >
            alfasip_paine
          </text>
        </a>
      </div> */}
    </div>
  );
}
